<template>
  <div class="bodylist">
    <div class="tname">
      <p>
        <img style="margin-left: 20px" src="../../assets/image/logo.png" />
        <span style="font-size: 36px; margin-left: 10px; font-weight: 800"
          >中联燃气</span
        >
        <img style="margin-left: 50%" src="../../assets/image/logo_r.png" />
      </p>
    </div>
    <div class="bgcontent">
      <div class="showform">
        <el-form
          ref="addform"
          :model="form"
          label-width="130px"
          :rules="rule"
          size="small"
         
        >
          <p class="detil-code">报装信息</p>
          <div style="margin-left:30px">
            
            <el-row>
              <el-col :span="16">
                <el-form-item label="用气类型" prop="type" style="width:463px">
                  <el-select
                    v-model="form.type"
                    placeholder="请选择"
                    @change="selectType"
                    style="width: 173px"
                  >
                    <el-option
                      v-for="item in typeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="16">
                <el-form-item
                  :label="form.type == 1 ? '法人姓名' : '产权人姓名'"
                  prop="name"
                  name="limitLength"
                  maxlength="100"
                >
                  <el-input
                    v-model="form.name"
                    :placeholder="
                      form.type == 1 ? '请输入法人姓名' : '请输入产权人姓名'
                    "
                    name="limitLength"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-col>
              </el-row>
            <el-row>
              <el-col :span="16">
                <el-form-item
                  :label="form.type == 1 ? '法人身份证号' : '身份证号'"
                  prop="idcard"
                >
                  <el-input
                    v-model="form.idcard"
                    :placeholder="
                      form.type == 1 ? '请输入法人身份证号' : '请输入身份证号'
                    "
                    name="limitLength"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="16" v-if="form.type == 1">
                <el-form-item
                  label="公司名称"
                  prop="corporation"
                  name="limitLength"
                  maxlength="100"
                >
                  <el-input
                    v-model="form.corporation"
                    placeholder="请输入公司名称"
                    name="limitLength"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-col>
              </el-row>
            <el-row>
              <el-col :span="16">
                <el-form-item
                  :label="form.type == 1 ? '公司地址' : '地址'"
                  prop="address"
                  name="limitLength"
                  maxlength="100"
                >
                  <el-input
                    v-model="form.address"
                    :placeholder="
                      form.type == 1 ? '请输入公司地址' : '请输入地址'
                    "
                    name="limitLength"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="16">
                <el-form-item label="联系人电话" prop="tel">
                  <el-input
                    v-model="form.tel"
                    placeholder="联系人电话"
                    name="limitLength"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-col>
              </el-row>
            <el-row>
              <el-col :span="16">
                <el-form-item label="用途" prop="useDesc">
                  <el-select
                    v-model="form.useDesc"
                    placeholder="请选择"
                    class="SearchOptions"
                  >
                    <el-option
                      v-for="item in addUseList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div>
              <p class="detil-code">上传法人身份证<span style="color:#B1B1B1;height:80px;line-height:80px;vertical-align:bottom;">(非必填)</span></p>
            <el-row>
              <el-col :span="16">
                <el-form-item label="" prop="endDate">
                    <div style="display: flex;">
                  <div style="flex:1;overflow:hidden;height:136px;width:204px">
                    <el-upload
                      ref="upload1"
                      action="#"
                      :auto-upload="true"
                      :http-request="repairBeforUploadFun1"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove1"
                      :before-upload="beforUploadImg"
                      :on-change="beforHFhandleChangeImg1"
                      :file-list="repairBeforFilefileList1"
                      accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                      :limit="1"
                      :on-exceed="msgLimit1"
                    >
                      <img
                        src="../../assets/image/upload1.png"
                        style=""
                        class="uploadImg1"
                        
                      />
                    </el-upload>
                  </div>
                  <div style="flex:1;overflow:hidden;height:136px;width:204px">
                    <el-upload
                      ref="upload2"
                      action="#"
                      :auto-upload="true"
                      :http-request="repairBeforUploadFun2"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove2"
                      :before-upload="beforUploadImg"
                      :on-change="beforHFhandleChangeImg2"
                      :file-list="repairBeforFilefileList2"
                      accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                      :limit="1"
                      :on-exceed="msgLimit1"
                    >
                      <img
                        src="../../assets/image/upload2.png"
                         class="uploadImg2" 
                        v-show="upload2Add"
                      />
                    </el-upload>
                  </div>
                    </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div>
               <p class="detil-code">上传{{form.type == 1 ? '营业执照' : '房产证(购房合同)'}}<span style="color:#B1B1B1;height:80px;line-height:80px;vertical-align:bottom;">(非必填)</span></p>
            <el-row>
              <el-col :span="16">
                <el-form-item
                  prop="urlList"
                >
                  <el-upload
                    ref="upload"
                    action="#"
                    :auto-upload="true"
                    :http-request="repairBeforUploadFun3"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove3"
                    :before-upload="beforUploadImg"
                    :on-change="beforHFhandleChangeImg3"
                    :file-list="repairBeforFilefileList3"
                    accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                    :limit="10"
                    :on-exceed="msgLimit"
                  >
                    <img
                      src="../../assets/image/upload3.png"
                      class="uploadImg1"
                    />
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
        <div class="footer">
        <el-button type="primary" @click="saveadd">提 交</el-button>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import {bzApplyWeChat_save,uploadImg} from '../../RequestPort/pc/pc.js'
import {selectDictListByParentCode } from '@/apis/commonType'
export default {
  name: "home",
  data() {
    return {
      form: {type:'1'},
      addUseList: [],
       typeList:[{label:'工商户（企业客户）',value:'1'},{label:'个人户（居民客户）',value:'2'}],
      rule: {
        type: {
          type: "string",
          required: true,
          message: "请选择用气类型",
          trigger: ["blur", "change"],
        },
        name: {
          type: "string",
          required: true,
          message: "请输入姓名",
          trigger: ["blur", "change"],
        },
        idcard: {
          type: "string",
          required: true,
          message: "请输入正确的身份证号",
          trigger: ["blur", "change"],
          pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
        },
        corporation: {
          type: "string",
          required: true,
          message: "请输入公司名称",
          trigger: ["blur", "change"],
        },
        address: {
          type: "string",
          required: true,
          message: "请输入地址",
          trigger: ["blur", "change"],
        },
        tel: {
          type: "string",
          required: true,
          message: "请输入正确的联系人电话",
          pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
          trigger: ["blur", "change"],
        },
        useDesc: {
          required: true,
          message: "请选择用途",
          trigger: ["blur", "change"],
        },
      },
      fileList1: [],
      fileList2: [],
      fileList3: [],
      urlList1: [],
      urlList2: [],
      urlList3: [],
      upload1Add: true,
      upload2Add: true,
      repairBeforFilefileList1: [],
      repairBeforFilefileList2: [],
      repairBeforFilefileList3: [],
      repairBeforFileFormData: new FormData(),
      dialogImageUrl: "",
      dialogVisibleImg: false,
    };
  },
  methods: {
    goPath(path) {
      this.$router.push({ path: path, query: {} });
    },
    getOption(type){
        
          if(type){
            var code = type==1?'70001':'70000';
          }
          selectDictListByParentCode({parentCode:code}).then(res=>{
            if(res.code==200){
              this.useList = res.data
              this.addUseList = res.data
            }
          })
        
      },
    selectType(val){
      this.getOption(val)
    },
    repairBeforUploadFun1(e) {
      this.repairBeforFileFormData.append("file", e.file);
      uploadImg(this.repairBeforFileFormData).then((res) => {
        this.urlList1.push(res.data.url);
        this.repairBeforFileFormData.delete("file");
      });
    },
    repairBeforUploadFun2(e) {
      this.repairBeforFileFormData.append("file", e.file);
      uploadImg(this.repairBeforFileFormData).then((res) => {
        this.urlList2.push(res.data.url);
        this.repairBeforFileFormData.delete("file");
      });
    },
    repairBeforUploadFun3(e) {
      this.repairBeforFileFormData.append("file", e.file);
      uploadImg(this.repairBeforFileFormData).then((res) => {
        this.urlList3.push(res.data.url);
        this.repairBeforFileFormData.delete("file");
      });
    },
    beforUploadImg(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "JPG",
        "JPEG",
        "PBG",
        "GIF",
        "BMP",
      ];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error("上传文件只能是 jpg、jpeg、png、gif、bmp");
        return false;
      }
      const size = file.size / 1024 / 1024;
      if (size > 100) {
        this.$message.error("大小必须小于100M");
        return false;
      }
    },
    beforHFhandleChangeImg1(file, fileList) {
      this.upload1Add = false;
      this.repairBeforFilefileList1 = fileList;
    },
    beforHFhandleChangeImg2(file, fileList) {
      this.upload2Add = false;
      this.repairBeforFilefileList2 = fileList;
    },
    beforHFhandleChangeImg3(file, fileList) {
      this.repairBeforFilefileList3 = fileList;
    },
    handleRemove1(file, fileList) {
      let index = this.getArrayIndex(this.repairBeforFilefileList1, file);
      this.urlList1.splice(index, 1);
      setTimeout(() => {
        this.upload1Add = true;
      }, 1000);
    },
    handleRemove2(file, fileList) {
      let index = this.getArrayIndex(this.repairBeforFilefileList2, file);
      this.urlList2.splice(index, 1);
      setTimeout(() => {
        this.upload2Add = true;
      }, 1000);
    },
    handleRemove3(file, fileList) {
      let index = this.getArrayIndex(this.repairBeforFilefileList3, file);
      this.urlList3.splice(index, 1);
    },
    getArrayIndex(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return i;
        }
      }
      return -1;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleImg = true;
    },
    msgLimit1(file, fileList) {
      this.$message.error("最多上传1张图片");
    },
    msgLimit(file, fileList) {
      this.$message.error("最多上传10张图片");
    },
    saveadd(){
       this.$refs['addform'].validate(valid => {
            if (valid) {
              if(this.urlList1.length>0){
                  this.form.idcard1Image = this.urlList1[0]
              }else{
                this.form.idcard1Image =''
              }
              if(this.urlList2.length>0){
                  this.form.idcard2Image = this.urlList2[0]
              }else{
                this.form.idcard2Image =''
              }
              if(this.urlList3.length>0){
                 this.form.contractImageList = this.urlList3;
              }else{
                this.form.contractImageList = [];
              }
              this.form.sourceType = 4
              bzApplyWeChat_save(this.form).then(res=>{
                this.dialogVisible = false;
                  this.$router.push({ path: '/succeed', query: {} })
              })
              
            }
       })
    }
  },
  mounted(){
    this.getOption(1)
  }
};
</script>

<style  lang="less" scoped>

::v-deep .el-upload--picture-card {
  height: 136px;
  width: 204px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  height: 136px;
  width: 204px;
}
::v-deep .el-upload--picture-card {
  border: none;
}
.uploadImg1 {
  height: 136px;
  width: 204px;
}
.uploadImg2 {
  height: 136px;
  width: 204px;
  margin-left: 15px;
}
.bodylist {
   margin: 0px 15%;
}
.tname {
  font-size: 0.5rem;
  text-align: center;
   margin-top:15px;
  p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 0.7rem;
    width: 100%;
    margin: 0 auto;
  }
}
.bgcontent {
  background-image: url("../../assets/indexpc/indexbg.png");
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}
.showform {
  background: #fff;
  margin: 30px;
  position: relative;
  overflow: auto;
  height: calc(100vh - 180px);
  top:15px;
 
}
.detil-code{
	padding-left: 30px;
	font-size: 22px;
	font-weight: bolder;
	height: 80px;
	line-height: 80px;
	color: #000000;
	position: relative;
}
.detil-code:before{
	content: '';
	position: absolute;
	width: 4px;
	height: 24px;
	background-color: #007AFF;
	top: 30px;
	left: 0;
}
.footer{width: 100%;text-align: center;padding-bottom: 40px}
</style>